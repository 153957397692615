import React from 'react'
import { v4 as uuid } from 'uuid'
import { Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { useMediaQuery, useTheme } from '@mui/material'
// types
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMExternalProduct } from '@typesApp/cmsPlacement/CMExternalProduct'
import { ICMProductTeaser } from '@typesApp/cmsPlacement/CMProductTeaser'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
// components
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { CmsProductTile } from '@components/Cms/CmsComponents-CSS/CmsProductTile'
import GridItem from '@components/UI-CSS/Grid/GridItem'
import { StyledLinkInline } from '@components/UI/Button/LinkInline'
import { StyledTab } from '@components/UI/Tabs/StyledTab'
import { StyledTabPanel } from '@components/UI/Tabs/StyledTabPanel'
import { ITabs } from '@components/UI/Tabs/StyledTabs'
import { StyledTabsWrapper } from '@components/UI/Tabs/StyledTabsWrapper'
import { CategoryItemWithSlide, ProductItemWithSlide, ProductItemWithTabs, ProductItemWithTiles } from './ProductItem'
// styles
import {
  StyledCTAContainerForProductCarousel,
  StyledSwiperContent,
  TextContainer,
} from '@components/CmsModules/TextModuleV2/TextModule.style'
import { StyledProductCarouselGrid } from '@components/CmsPlacement/GridOfProducts/GridOfProducts.style'
import {
  ProductCarouselGridContainer,
  ProductCarouselHeader,
  ProductCarouselStyledTitle,
  ProductCarouselTabsContainer,
  ProductHeaderGridContainer,
  ProductListLink,
  StyledSwiper,
  StyledViewAllContainer,
  TitleProductCarousel,
} from '@components/CmsPlacement/ProductCarousel/ProductCarousel.style'
import { StyledTabsBestSellerWrapper, StyledTabsWithStyle } from '@components/UI/Tabs/StyledTabs.style'
import { ctaBgMap } from '@constants/ui'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import useBreakpoints from '@hooks/useBreakpoints'
import { uniqueId } from '@utils/common'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'
import { CmsCarousel } from '@components/Cms/CmsComponents-CSS/CmsCarousel/CmsCarousel'
import productCarouselStyle from './styles/productCarousel.module.scss'
import productTileStyle from './styles/productTile.module.scss'
import { initIndexName } from '@foundation/algolia/algoliaConfig'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { IAlgoliaHit } from '@typesApp/product'
import { useGetRecommendedProducts } from '@components/AlgoliaRecommendations/AlgoliaRecommendations'

const ProductCarousel: React.FC<{
  placement: IPlacement
  index?: number
  cardLayout?: boolean
  enableAlgoliaProducts?: boolean
}> = ({ placement, index: placementIndex, cardLayout, enableAlgoliaProducts }) => {
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })
  const { recommendations: trendingItemsFromAlgolia, status: algoliaTrendingItemsLoading } = useGetRecommendedProducts(
    'trendingitems',
    indexName,
    [''],
    '',
    12
  )
  const item = placement?.items?.find(isCMCollection)
  const teasers = placement.items.filter(isLXTeaser)
  const viewType = placement?.viewtype
  const isClyProductType = viewType === 'cly-products-category-tabs' || viewType === 'cly-four-products-category-tabs'
  const _tabTeasableItems = (item?.teasableItems as ICMCollection[]) ?? []
  const tabTeasableItems: ICMCollection[] = isClyProductType
    ? placement?.items.filter(isCMCollection)
    : _tabTeasableItems
  const theme = useTheme()
  const [getSelectedIndex, setSelectedIndex] = React.useState(0)
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const { isMobile, isDesktop, isTabletWidthAbove768 } = useBreakpoints()

  type IProductType = (ICMExternalProduct | ICMProductTeaser)[]

  const getSlideSettings = viewType => {
    switch (viewType) {
      case 'three-products-carousel-slide':
        return { slidePerView: 3, template: ProductItemWithSlide, viewType: 'slide' }
      case 'four-item-board-carousel-slide':
        return { slidePerView: 4, template: ProductItemWithSlide, viewType: 'slide' }
      case 'four-item-board-carousel-tile':
        return { slidePerView: 4, template: ProductItemWithTiles, viewType: 'tile' }
      case 'cly-products-w-cta':
      case 'cly-products':
        return { slidePerView: !isMobile ? item?.collectionMaxElementNumber : 1, template: ProductItemWithTabs }
      default:
        return { slidePerView: 4, template: ProductItemWithSlide, viewType: 'slide' }
    }
  }

  const getCategorySlideSettings = () => {
    return {
      slidePerView: isDesktop || isTabletWidthAbove768 ? item?.collectionMaxElementNumber : 2,
      template: CategoryItemWithSlide,
      viewType: 'slide',
    }
  }

  const isCategoryItem = (viewType: IViewType | undefined) => {
    return viewType === 'cly-category-with-cta'
  }

  const getSwiperProductData = (teasableItems: IProductType) => teasableItems.flatMap(x => x.productData ?? [])

  const slideSettings = getSlideSettings(item?.viewtype)
  const { slidePerView: slidePerViewSetting, viewType: viewTypeSetting } = slideSettings || {}

  let tabElements: ITabs[] = []
  const teasableItemsFromAlgolia = tabTeasableItems.map(item => {
    const category = item.collectionTitle.toLowerCase().split(' ').join('-')
    return {
      ...item,
      fromAlgolia: trendingItemsFromAlgolia.filter(itemFromAlgolia => {
        return itemFromAlgolia['url'].includes(`${langCode}/${category}`)
      }),
    }
  })
  const tabProducts = enableAlgoliaProducts ? teasableItemsFromAlgolia : tabTeasableItems
  const tabTeasableLength: number = tabProducts.length
  const ProductView = slideSettings.template
  const CategoryView = getCategorySlideSettings().template

  tabProducts.map((tabItem, index) => {
    const productsWithValidUrl = tabItem.teasableItems.map(item => {
      if ('formattedUrl' in item && 'productData' in item) {
        return {
          ...item,
          productData: {
            ...item.productData,
            seo: {
              ...item.productData.seo,
              href: item.formattedUrl,
            },
          },
        }
      }

      return item
    })

    let productItems
    if (enableAlgoliaProducts && isClyProductType) {
      productItems = tabItem['fromAlgolia'].map((hit: Partial<IAlgoliaHit>) => ({
        ...hit,
        x_price: { ...hit.prices },
      }))
    } else {
      if (viewType === 'cly-products-category-tabs') {
        productItems = productsWithValidUrl // for category this returns 6 sparsely populated things..
      } else {
        productItems = isClyProductType
          ? getSwiperProductData(productsWithValidUrl as IProductType)
          : productsWithValidUrl
      }
    }

    if (cardLayout) {
      tabElements.push({
        title: tabItem.collectionTitle,
        tabContent: (
          <CmsCarousel
            isAlgolia={enableAlgoliaProducts}
            products={productItems}
            styleOverride={[productCarouselStyle, productTileStyle]}
            productTileProps={{
              hidePriceFromLabel: true,
              verticalPriceLayout: false,
              alignPriceCenter: true,
            }}
          />
        ),
      })
    } else {
      const itemsPerTab = productItems.length

      const slidesPerView = !isMobile
        ? slidePerViewSetting && itemsPerTab > slidePerViewSetting
          ? slidePerViewSetting
          : itemsPerTab
        : 2

      tabElements.push({
        title: tabItem.collectionTitle,
        tabContent:
          viewTypeSetting === 'slide' || isDesktop || isTabletWidthAbove768 || tabTeasableLength > 2 ? (
            <StyledSwiperContent key={`ProductCarousel_SwiperContent_${index}`}>
              <StyledSwiper
                slidesPerView={slidesPerView}
                slidesPerGroup={slidesPerView}
                loop={false}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Pagination]}
                spaceBetween={32}
                viewtype={item?.viewtype}
              >
                {productItems.map((element, itemIndex) => {
                  const newElement = {
                    ...element,
                    placementCounter: { placementIndex, tabIndex: index, tileIndex: itemIndex },
                  }
                  return (
                    <SwiperSlide key={`ProductCarousel_SwiperSlide_${index}_${itemIndex}`}>
                      {isCategoryItem(item?.viewtype) && (
                        <CategoryView
                          crop={'PRODUCT_4_ITEMS'}
                          teaser={newElement || isCMCollection}
                          viewType={tabItem?.viewtype || placement?.viewtype}
                        />
                      )}
                      {!isCategoryItem(item?.viewtype) && (
                        <ProductContextProvider productData={{ product: newElement }}>
                          <ProductView
                            crop={'PRODUCT_4_ITEMS'}
                            preventImagelazyLoad={false}
                            gridKeyElement={element?.uniqueID}
                            teasableItems={tabItem.teasableItems}
                            placementViewType={viewType}
                            teaser={newElement || isCMCollection}
                            viewType={tabItem?.viewtype || placement?.viewtype}
                            useSkuPriceFallback={true}
                          />
                        </ProductContextProvider>
                      )}
                    </SwiperSlide>
                  )
                })}
              </StyledSwiper>
            </StyledSwiperContent>
          ) : (
            <ProductCarouselGridContainer
              key={`grid-container-${uuid()}`}
              container={true}
              gap={!isDesktop && !isTabletWidthAbove768 ? 8 : 16}
            >
              {isClyProductType
                ? getSwiperProductData(tabItem.teasableItems as IProductType).map((product, i) => {
                    const newProduct = {
                      ...product,
                      placementCounter: { placementIndex, tabIndex: index, tileIndex: i },
                    }
                    return (
                      <GridItem key={`grid-item-${uuid()}`} sm={2} md={6}>
                        <StyledProductCarouselGrid>
                          {product && (
                            <ProductContextProvider productData={{ product: newProduct }}>
                              <CmsProductTile viewType={item?.viewtype} hideHeader hidePrice preventImagelazyLoad />
                            </ProductContextProvider>
                          )}
                        </StyledProductCarouselGrid>
                      </GridItem>
                    )
                  })
                : tabItem.teasableItems.map(element => (
                    <GridItem key={`grid-item-${uuid()}`} sm={2} md={6}>
                      <ProductItemWithTiles teaser={element as ILXTeaser} viewType={tabItem?.viewtype} />
                    </GridItem>
                  ))}
            </ProductCarouselGridContainer>
          ),
      })
    }
  })

  const a11yProps = index => {
    return {
      id: `ProductCarouselTabs-tab-${uniqueId(`${index}-`)}`,
      'aria-controls': `ProductCarouselTabs-tabpanel-${uniqueId(`${index}-`)}`,
    }
  }

  const handleChange = (_, newValue) => {
    setSelectedIndex(newValue)
  }

  if (enableAlgoliaProducts && (algoliaTrendingItemsLoading === 'loading' || trendingItemsFromAlgolia.length === 0)) {
    return null
  }

  return (
    <StyledTabsWrapper parentbg="light">
      {tabElements.length > 1 ? (
        <ProductCarouselTabsContainer cardLayout={cardLayout}>
          <ProductHeaderGridContainer gap={24} cardLayout={cardLayout}>
            <GridItem sm={12} md={3} xxl={4}>
              <TitleProductCarousel>
                {isClyProductType ? placement?.items.filter(isLXTeaser)[0].teaserTitle1 : item?.collectionTitle}
              </TitleProductCarousel>
            </GridItem>
            <GridItem sm={12} md={6} xxl={4}>
              <StyledTabsBestSellerWrapper>
                <StyledTabsWithStyle
                  parentbg="light"
                  value={getSelectedIndex}
                  onChange={handleChange}
                  aria-label="tabs example"
                  scrollButtons={false}
                  centered={!isMobileOrTablet}
                  variant={isMobileOrTablet ? 'scrollable' : 'fullWidth'}
                >
                  {tabElements &&
                    tabElements.length > 0 &&
                    tabElements.map((tab, index) => {
                      const { title } = tab
                      return (
                        <StyledTab
                          key={`tab-item-${uuid()}`}
                          label={title}
                          parentbg="light"
                          name="ProductCarouselTabs"
                          {...a11yProps(index)}
                        />
                      )
                    })}
                </StyledTabsWithStyle>
              </StyledTabsBestSellerWrapper>
            </GridItem>
            <GridItem sm={12} md={3} xxl={4}>
              <ProductListLink>
                {isClyProductType && (isDesktop || isTabletWidthAbove768)
                  ? teasers[getSelectedIndex + 1]?.teaserLXCallToActionSettings &&
                    teasers[getSelectedIndex + 1].teaserLXCallToActionSettings.map(ctaItem => (
                      <StyledLinkInline
                        key={`link-item-${uuid()}`}
                        labeltext={ctaItem.callToActionText}
                        variant={'separate'}
                        arrowPosition={'right'}
                        parentbg={ctaBgMap[ctaItem?.style.split('-')[3]] || 'light'}
                        href={ctaItem.target.formattedUrl || ''}
                      ></StyledLinkInline>
                    ))
                  : item?.teaserLXCallToActionSettings &&
                    item?.teaserLXCallToActionSettings.length > 0 &&
                    item.teaserLXCallToActionSettings.map(ctaItem => {
                      return (
                        <StyledLinkInline
                          key={`link-item-${uuid()}`}
                          labeltext={ctaItem.callToActionText}
                          variant={'separate'}
                          arrowPosition={'right'}
                          parentbg={ctaBgMap[ctaItem?.style.split('-')[3]] || 'light'}
                          href={ctaItem.target.formattedUrl || ''}
                        ></StyledLinkInline>
                      )
                    })}
              </ProductListLink>
            </GridItem>
          </ProductHeaderGridContainer>
        </ProductCarouselTabsContainer>
      ) : (
        <ProductCarouselHeader>
          <ProductCarouselStyledTitle>{tabProducts[0]?.collectionTitle}</ProductCarouselStyledTitle>
          <TextContainer dangerouslySetInnerHTML={{ __html: tabProducts[0]?.collectionText }} textAlign="center" />
        </ProductCarouselHeader>
      )}
      {tabElements?.map((v, index: number) => (
        <StyledTabPanel key={`tab-panel-${uuid()}`} value={getSelectedIndex} index={index} name="ProductCarouselTabs">
          {v.tabContent}
        </StyledTabPanel>
      ))}
      {((!isDesktop && !isTabletWidthAbove768) || tabElements.length === 1) && (
        <StyledCTAContainerForProductCarousel overlayTextAlign={cmsApiService.getTeaserOverlayTextAlign('center')}>
          {isClyProductType && !isDesktop && !isTabletWidthAbove768
            ? teasers[getSelectedIndex + 1]?.teaserLXCallToActionSettings?.map((ctaItem, index) => (
                <StyledViewAllContainer
                  key={`cta-${uuid()}`}
                  cardLayout={cardLayout}
                  data-element-id={`X_X_Product_Carousel_${index}Placement_Banner_${ctaItem.callToActionText?.replaceAll(
                    ' ',
                    '_'
                  )}_CTA${index}`}
                >
                  <CmsCta
                    dataElementId={`X_X_Product_Carousel_${index}Placement_Banner_${ctaItem.callToActionText?.replaceAll(
                      ' ',
                      '_'
                    )}_CTA${index}`}
                    teaserCtaSetting={ctaItem}
                  />
                </StyledViewAllContainer>
              ))
            : item?.teaserLXCallToActionSettings &&
              item?.teaserLXCallToActionSettings.length > 0 &&
              item.teaserLXCallToActionSettings.map((ctaItem, index) => {
                return (
                  <CmsCta
                    dataElementId={`X_X_Product_Carousel_${index}Placement_Banner_${ctaItem.callToActionText?.replaceAll(
                      ' ',
                      '_'
                    )}_CTA${index}`}
                    key={`cta-${uuid()}`}
                    teaserCtaSetting={ctaItem}
                  />
                )
              })}
        </StyledCTAContainerForProductCarousel>
      )}
    </StyledTabsWrapper>
  )
}

export default ProductCarousel
