import { useContext } from 'react'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { TextModuleBanner } from '../TextModuleBanner'
import { ShoppableTeaser } from '../ShoppableTeaser'
import { hasOverlay, hasTermsAndConditions, isShoppableTeaser } from '@utils/cms/teaser'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { CmsMedia } from '../CmsMedia'
import styles from '../Media/MediaWrapper.module.scss'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import heroBannerStyles from './styles/HeroBanner.module.scss'
import {CRITICAL_INDEX} from '@components/Cms/constants'

type HeroBannerProps = {
  videoWrapperClassName?: string
}

export const HeroBanner: React.FC<HeroBannerProps> = props => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teasers = data?.placement?.items?.filter(isLXTeaser) || []

  const { videoWrapperClassName } = props

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={20}
      slidesPerView={1}
      loop={true}
      navigation={true}
      pagination={{ clickable: true }}
      className={heroBannerStyles.heroBanner}
    >
      {teasers.map((teaser, index) => {
        const {
          media,
          teaserLXCallToActionSettings,
          teaserBackground,
        } = teaser || {}

        const isShoppable = isShoppableTeaser(teaser)
        const hasTerms = hasTermsAndConditions(teaser)

        return (
          <SwiperSlide key={index}>
            <section
              className={clsx(
                'cms-section',
                styles['media-wrapper'],
                heroBannerStyles.heroBanner,
                teaserBackground,
                {
                  [`has-cta-${teaserLXCallToActionSettings?.length}`]: true,
                  'no-cta': !teaserLXCallToActionSettings?.length,
                  'is-shoppable': isShoppable,
                  'has-terms': hasTerms,
                  'has-overlay': true,
                }
              )}
            >
              {isShoppable && <ShoppableTeaser />}
              <CmsMedia
                media={media}
                hasOverlay={hasOverlay(teaser)}
                lazy={data?.index >= CRITICAL_INDEX}
                cropType="LANDSCAPE_BANNER_M"
                videoClassName={videoWrapperClassName}
              />
              <TextModuleBanner  />
            </section>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

