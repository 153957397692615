import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  StyledNewsletterBody,
  StyledNewsletterContainer,
  StyledNewsletterPrivacyText,
  StyledNewsletterTextFieldWrapper,
} from './NewsLetterSubscribe.style'
import addressUtil from '@utils/addressUtil'
import { TextField } from '@components/UI'
import { isEmpty } from 'lodash-es'
import { SubscribeButton } from '@views/ProductDetails/components/ProductAvailableNotificationDrawer/ProductAvailableNotificationDrawer.style'
import { NewsLetterResultObject } from './NewsLetterSubscribe'
import { useNewsletterSubscription } from '@foundation/hooks/useNewsletterSubscription'

type WelcomeProps = {
  doSubscribe: (email: string) => void
  result: NewsLetterResultObject
  setResult: (ResultObject) => void
  isBusy?: boolean
}

export const WelcomeContainer: React.FC<WelcomeProps> = ({ doSubscribe, result, setResult, isBusy }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  const [email, setEmail] = useState('')
  const { isTextFieldExists, emailLabel, isSubscribeButtonExists, subscribeCtaText, privacyText } =
    useNewsletterSubscription()

  const onChange = e => {
    setResult({})
    setEmail(e.target.value)
    setError(!addressUtil.validateEmail(e.target.value))
  }

  const subscribe = async () => {
    if (addressUtil.validateEmail(email)) {
      doSubscribe(email)
    } else {
      setError(true)
    }
  }

  return (
    <StyledNewsletterContainer>
      <div>
        <StyledNewsletterBody>
          {isTextFieldExists && (
            <StyledNewsletterTextFieldWrapper>
              <TextField
                error={error || result.error}
                helperText={
                  error && isEmpty(result)
                    ? t('NewsLetterDrawerContent.Errors.Input')
                    : result.error
                      ? result.text
                      : null
                }
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                label={emailLabel}
                type="email"
                id="email"
                value={email}
                required
                showvalidationstatus={true}
                onChange={onChange}
              />
            </StyledNewsletterTextFieldWrapper>
          )}
          {isSubscribeButtonExists && (
            <SubscribeButton disabled={!email || error || isBusy} onClick={subscribe} fullwidth>
              {subscribeCtaText}
            </SubscribeButton>
          )}
        </StyledNewsletterBody>
      </div>
      {!!privacyText && (
        <StyledNewsletterPrivacyText
          dangerouslySetInnerHTML={{
            __html: privacyText,
          }}
        />
      )}
    </StyledNewsletterContainer>
  )
}
