import React, { useContext } from 'react'
import GenericBanner from '../GenericBanner'
import { HeroBanner } from '../HeroBanner'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { CRITICAL_INDEX } from '@components/Cms/constants'
import styles from './styles/LandscapeBanner.module.scss'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'

const LandscapeBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null
  const { data } = context as ContextWrapperData<PlacementContextType>
  const isWithCarousel = (data?.placement?.items?.filter(isLXTeaser) || []).length > 1

  return isWithCarousel ? (
    <HeroBanner videoWrapperClassName={styles.landscapeBannerVideoWrapper} />
  ) : (
    <GenericBanner
      crop="LANDSCAPE_BANNER_M"
      className={styles.landscapeBanner}
      lazy={data?.index >= CRITICAL_INDEX}
      termsAndConditionsClassName={styles.landscapeBannerTermsConditions}
      videoWrapperClassName={styles.landscapeBannerVideoWrapper}
    />
  )
}

export default LandscapeBanner
