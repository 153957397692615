import React from 'react'
import dynamic from 'next/dynamic'
import { isArray } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import theme from '../../../themes/Clearly/light-theme'
import HTMLReactParser from 'html-react-parser'

// components
import AlgoliaRecommendations from '@components/AlgoliaRecommendations/AlgoliaRecommendations'
import BoardWithFields from '@components/CmsPlacement/BoardWithFields'
import BoardWithIcons from '@components/CmsPlacement/BoardWithIcons'
import BoxAndProducts from '@components/Cms/CmsComponents-CSS/BoxAndProducts'
import BoxWithMargin from '@components/Cms/CmsComponents-CSS/BoxWithMargin'
import ComboMiniSlider from '@components/CmsPlacement/ComboMiniSlider/ComboMiniSlider'
import DcwProducts from '@components/Cms/CmsPlacement/CmsBanner/DcwProducts'
import Faqs from '@components/CmsPlacement/Faqs'
import FullWidthBanner from '@components/Cms/CmsComponents-CSS/FullWidthBanner'
import GridOfBoards from '@components/CmsPlacement/GridOfBoards'
import GridOfProducts from '@components/CmsPlacement/GridOfProducts'
import LandscapeBanner from '@components/Cms/CmsComponents-CSS/LandscapeBanner'
import MediaOverlapV2 from '@components/CmsModules/MediaOverlapV2'
import ProductCarousel from '@components/CmsPlacement/ProductCarousel'
import PlainSlider from '@components/CmsPlacement/PlainSlider'
import ProductCollectionCarousel from '@components/Cms/CmsComponents-CSS/ProductCollectionCarousel'
import QueryList from '@components/CmsPlacement/QueryList'
import RecentlyViewedCarousel from '@components/Cms/CmsComponents-CSS/RecentlyViewedCarousel'
import SizeGuide from '@views/ProductDetails/components/SizeGuide/SizeGuide'
import SquareBoardWithSplit from '@components/Cms/CmsPlacement/CmsBanner/SquareBoardWithSplit'
import SquareBoardWithoutSplit from '@components/Cms/CmsPlacement/CmsBanner/SquareBoardWithoutSplit'
import SquatBanner from '@components/Cms/CmsComponents-CSS/SquatBanner'
import TableModule from '@components/CmsModules/TableModule'
// TODO: clean up duplicate TextModules
import TextModule from '@components/CmsModules/TextModule'
import TextModuleV1 from '@components/Cms/CmsPlacement/CmsBanner/TextModule'
import TextModuleV2 from '@components/CmsModules/TextModuleV2'
import TopPageBanner from '@components/Cms/CmsComponents-CSS/TopPageBanner'
import TwoColumnFullWidthBanner from '@components/CmsPlacement/TwoColumnFullWidthBanner'
import { ValuePropBanner } from '../../../layouts/Header/components/ValuePropBanner'
import WallOfBrands from '@components/CmsPlacement/WallOfBrands'
import PromoStrip from '@components/CmsPlacement/PromoStrip'

// constants
import { CMS_MODULES as CM } from '@components/Cms/constants'

// types
import {
  isCMProductList,
  isCMQueryList,
  ITablePlacement,
  isCMCollection,
  isCMPlaceholder,
  isCMHtml,
  IPlacement,
} from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { ModulesConfigProps } from '@typesApp/cms'

// utils
import {
  isAccessoriesProduct,
  isCLAccessoriesProduct,
  isContactLensesProduct,
  isElectronicsProduct,
} from '@utils/product'
import { ModulesByPlacementProps } from './types'
import { NewsLetterSubscribe } from '@components/NewsLetterSubscribe/NewsLetterSubscribe'
import { MentionMePlaceholder } from '@components/MentionMe/MentionMePlaceholder'

const AnchorButtons = dynamic(() => import('@components/Cms/CmsPlacement/CmsBanner/AnchorButtons/AnchorButtons'), {
  ssr: false,
})

/**
 * Determines whether placement is available in activePlacements
 *
 * @param placementViewtype
 * @param activePlacements
 * @returns boolean
 */
export const isPlacementVisible = (placementViewtype: IViewType, activePlacements: IViewType[]): boolean => {
  return activePlacements?.includes(placementViewtype) || false
}

/**
 * Populates components to be rendered for each view type
 * @returns array of modules to render data
 */
export const getCmsModulesByPlacement = (props: ModulesByPlacementProps): ModulesConfigProps[] | null => {
  const placementModules = getPlacementModules(props)

  if (isArray(placementModules)) {
    const modules: ModulesConfigProps[] = []
    placementModules?.map(item =>
      modules.push({
        module: () => item,
      })
    )
    return modules
  } else if (placementModules) {
    return [
      {
        module: () => placementModules,
      },
    ]
  }

  return null
}

export const getDefaultViewPlacement = (placement: IPlacement) => {
  if (placement.items?.length === 0) {
    return null
  }

  const collection = placement.items?.filter(item => item !== null).find(isCMCollection)
  const placeholder = placement.items?.filter(item => item !== null).find(isCMPlaceholder)
  const productList = placement.items?.filter(item => item !== null).find(isCMProductList)
  const queryList = placement.items?.filter(item => item !== null).find(isCMQueryList)
  const cmhtml = placement.items?.filter(item => item !== null).find(isCMHtml)
  return collection || productList || queryList || cmhtml || placeholder || null
}

/**
 * Returns module config for a placement item
 * @returns { ModulesConfigProps[] | null } array of module configs or null
 */
const getPlacementModules = (props: ModulesByPlacementProps) => {
  const { index, isPLP, pdpData, placement } = props
  const reflect: boolean = placement?.placementReflect ?? false
  const placementCenter: boolean = placement?.placementCenter ?? false
  const collection = placement as unknown as ICMCollection
  const teasableItem = collection?.teasableItems?.[0] as ILXTeaser

  const cmsModules = {
    [CM.ANCHOR_BUTTONS]: <AnchorButtons />,
    [CM.BOARD_WITH_4_ICONS]: (
      <BoardWithIcons
        columnAmount={1}
        placement={placement}
        iconCount={4}
        textColor={theme.palette.text.dark.primary}
        index={index}
      />
    ),
    [CM.BOARD_WITH_6_ICONS]: (
      <BoardWithIcons
        columnAmount={1}
        placement={placement}
        iconCount={6}
        textColor={theme.palette.text.light.primary}
        index={index}
      />
    ),
    [CM.BOARDS_WITH_FIELDS_BELOW]: (
      <BoardWithFields placement={placement} placementCenter={placementCenter} index={index} />
    ),
    [CM.BOARDS_WITH_FIELDS_BELOW_STACK]: (
      <BoardWithFields placement={placement} placementCenter={placementCenter} index={index} />
    ),
    [CM.BOX_AND_2_PRODUCTS]: <BoxAndProducts />,
    [CM.BOX_AND_4_PRODUCTS]: <BoxAndProducts />,
    [CM.BOX_WITH_MARGIN]: <BoxWithMargin />,
    [CM.BOX_WITHOUT_MARGIN]: <BoxWithMargin />,
    [CM.COMBO_MINI_SLIDER]: <ComboMiniSlider placement={placement} reflect={reflect} teaserIndex={index} />,
    [CM.CLY_PRODUCTS]: <ProductCollectionCarousel />,
    [CM.CLY_PRODUCTS_CATEGORY_TABS]: <ProductCarousel index={index} placement={placement} />,
    [CM.CLY_4_PRODUCTS_CATEGORY_TABS]: (
      <ProductCarousel index={index} placement={placement} cardLayout enableAlgoliaProducts />
    ),
    [CM.DCW_PRODUCTS]: <DcwProducts placement={placement} />,
    [CM.FAQS]: <Faqs placement={placement} />,
    [CM.FAQS_WITH_LINK]: <Faqs placement={placement} />,
    [CM.FOUR_PRODUCTS_CAROUSEL]: <ProductCollectionCarousel />,
    [CM.FULL_WIDTH_BANNER]: <FullWidthBanner />,
    [CM.FULL_WIDTH_BANNER_COLLECTION_PROMO]: [
      <MediaOverlapV2
        key={`placement-${uuid()}`}
        crop="FULL_WIDTH_BANNER_PROMO_L"
        placement={placement}
        teaser={teasableItem}
      />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.FULL_WIDTH_BANNER_COLLECTION_PROMO_MULTI]: [
      <MediaOverlapV2
        key={`placement-${uuid()}`}
        crop="FULL_WIDTH_BANNER_PROMO_L"
        placement={placement}
        teaser={teasableItem}
      />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.FULL_WIDTH_BANNER_WITH_SHADOW]: <TwoColumnFullWidthBanner />,
    [CM.FULL_WIDTH_PROMO_BANNER]: [
      <MediaOverlapV2 crop="FULL_WIDTH_BANNER_PROMO_L" key={`placement-${uuid()}`} placement={placement} />,
      <TextModule key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.FULL_WIDTH_PROMO_BANNER_2]: [
      <MediaOverlapV2 crop="FULL_WIDTH_BANNER_PROMO_L" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.FULL_WIDTH_PROMO_BANNER_3]: [
      <MediaOverlapV2 crop="FULL_WIDTH_BANNER_PROMO_L" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.FULL_WIDTH_PROMO_BANNER_4]: [
      <MediaOverlapV2 crop="FULL_WIDTH_BANNER_PROMO_L" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.GRID_OF_BOARDS_2_COLS]: <GridOfBoards columnAmount={2} placement={placement} />,
    [CM.GRID_OF_BOARDS_3_COLS]: <GridOfBoards columnAmount={3} placement={placement} />,
    [CM.GRID_OF_BOARDS_4_COLS]: <GridOfBoards columnAmount={4} placement={placement} />,
    [CM.GRID_OF_PRODUCTS]: <GridOfProducts placement={placement} />,
    [CM.INSTAGRAM_CAROUSEL]: <BoardWithFields placement={placement} index={index} />,
    [CM.LANDSCAPE_BANNER]: <LandscapeBanner />,
    [CM.LANDSCAPE_BANNER_COLLECTION_PROMO]: [
      <MediaOverlapV2
        crop="LANDSCAPE_BANNER_M"
        key={`placement-${uuid()}`}
        placement={placement}
        teaser={teasableItem}
      />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.LANDSCAPE_BANNER_COLLECTION_PROMO_MULTI]: [
      <MediaOverlapV2
        crop="LANDSCAPE_BANNER_M"
        key={`placement-${uuid()}`}
        placement={placement}
        teaser={teasableItem}
      />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.LANDSCAPE_PROMO_BANNER]: [
      <MediaOverlapV2 crop="LANDSCAPE_BANNER_PROMO_M" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.LANDSCAPE_PROMO_BANNER_2]: [
      <MediaOverlapV2 crop="LANDSCAPE_BANNER_PROMO_M" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.LANDSCAPE_PROMO_BANNER_3]: [
      <MediaOverlapV2 crop="LANDSCAPE_BANNER_PROMO_M" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.MOD_BOX_WITH_MARGIN]: <BoxWithMargin />,
    [CM.MOD_BOX_WITH_MARGIN_PRODUCTS]: <BoxWithMargin />,
    [CM.MOD_BOX_WITHOUT_MARGIN]: <BoxWithMargin />,
    [CM.MOD_BOX_WITHOUT_MARGIN_PRODUCTS]: <BoxWithMargin />,
    [CM.PDP_BANNER]: [
      <MediaOverlapV2 crop="PDP_BANNER" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.PLAIN_SLIDER]: <PlainSlider placement={placement} firstItem={placement} />,
    [CM.QUERY_LIST]: <QueryList placement={collection} />,
    [CM.RECENTLY_VIEWED]: <RecentlyViewedCarousel title={collection?.title} />,
    [CM.SIZE_GUIDE]:
      pdpData &&
      !isContactLensesProduct(pdpData) &&
      !isCLAccessoriesProduct(pdpData) &&
      !isAccessoriesProduct(pdpData) &&
      !isElectronicsProduct(pdpData) ? (
        <SizeGuide currentProduct={pdpData} />
      ) : null,
    [CM.SQUARE_BOARDS_WITH_SPLIT]: <SquareBoardWithSplit placement={placement} reflect={reflect} teaserIndex={index} />,
    [CM.SQUARE_BOARDS_WITHOUT_SPLIT]: (
      <SquareBoardWithoutSplit placement={placement} reflect={reflect} teaserIndex={index} />
    ),
    [CM.SQUAT_BANNER]: <SquatBanner />,
    [CM.TABLE_COMPLEX]: <TableModule placement={placement as ITablePlacement} />,
    [CM.TABLE_INDEX]: <TableModule placement={placement as ITablePlacement} />,
    [CM.TABLE_SIMPLE]: <TableModule placement={placement as ITablePlacement} />,
    [CM.TABLE_STAR]: <TableModule placement={placement as ITablePlacement} />,
    // TODO: clean up duplicate TextModules
    [CM.TEXT_MODULE]: isPLP ? <TextModuleV1 placement={placement} /> : <TextModule placement={placement} />,
    [CM.TEXT_MODULE_1_DEFAULT]: <TextModuleV2 placement={placement} />,
    [CM.THREE_ITEM_BOARD_CAROUSEL]: <BoardWithFields placement={placement} index={index} />,
    [CM.THREE_PRODUCTS_CAROUSEL]: <ProductCarousel placement={placement} index={index} />,
    [CM.TOP_PAGE_BANNER]: <TopPageBanner />,
    [CM.TOP_PAGE_BANNER_COLLECTION_PROMO]: [
      <MediaOverlapV2
        crop="TOP_PAGE_BANNER_S"
        key={`placement-${uuid()}`}
        placement={placement}
        teaser={teasableItem}
      />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.TOP_PAGE_BANNER_COLLECTION_PROMO_MULTI]: [
      <MediaOverlapV2
        crop="TOP_PAGE_BANNER_S"
        key={`placement-${uuid()}`}
        placement={placement}
        teaser={teasableItem}
      />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.TOP_PAGE_PROMO]: [
      <MediaOverlapV2 crop="TOP_PAGE_BANNER_PROMO_S" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.TOP_PAGE_PROMO_2]: [
      <MediaOverlapV2 crop="TOP_PAGE_BANNER_S" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.TOP_PAGE_PROMO_3]: [
      <MediaOverlapV2 crop="TOP_PAGE_BANNER_S" key={`placement-${uuid()}`} placement={placement} />,
      <TextModuleV2 key={`placement-${uuid()}`} placement={placement} />,
    ],
    [CM.TWO_COL_BANNER_LG]: <TwoColumnFullWidthBanner />,
    [CM.TWO_ITEM_BOARD_CAROUSEL]: <BoardWithFields placement={placement} index={index} />,
    [CM.VALUE_PROPOSITION_BANNER]: <ValuePropBanner data={placement} />,
    [CM.WALL_OF_BRANDS]: <WallOfBrands index={index} placement={placement} />,
    [CM.WALL_OF_BRANDS_WITH_TABS]: <WallOfBrands index={index} placement={placement} />,
    [CM.YOU_MAY_ALSO_LIKE]: (
      <AlgoliaRecommendations
        currentProduct={pdpData}
        item={collection}
        isGrouped={placement.clusterTile}
        sliderProps={placement?.options}
      />
    ),
    [CM.NEWS_LETTER_SUBSCRIBE]: <NewsLetterSubscribe />,
    [CM.NEWS_LETTER_LANDING_PAGE]: <NewsLetterSubscribe />,
    [CM.MENTION_ME_LANDING_PAGE]: <MentionMePlaceholder />,
    [CM.PROMO_STRIP]: <PromoStrip placement={placement} teaserIndex={index} />,
    default: getDefaultViewPlacementModule({ ...props }),
  }

  return placement?.viewtype ? cmsModules[placement.viewtype] : null
}

/**
 * Returns module config for `'default'` view placement items
 * @returns { ModulesConfigProps[] | null } array of module configs or null
 */
const getDefaultViewPlacementModule = props => {
  const { placement, ...otherProps } = props
  let firstItem = getDefaultViewPlacement(placement)
  if (firstItem !== null && firstItem.type === 'CMHTML') {
    const html = firstItem?.html ?? ''
    return <React.Fragment>{HTMLReactParser(html)}</React.Fragment>
  }

  if (firstItem !== null) {
    return getPlacementModules({ placement: firstItem, ...otherProps })
  }

  return null
}
