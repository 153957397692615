
import React, { useMemo } from 'react'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'

import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import clsx from 'clsx'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { CmsMedia } from '@components/Cms/CmsComponents-CSS/CmsMedia'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { CmsTeaserTitle } from '@components/Cms/CmsComponents-CSS/CmsTeaserTitle/CmsTeaserTitle'
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import styles from './styles/index.module.scss'
import { getDataElementId, trimText } from '@utils/common'
import { useCountdown } from '@hooks/useCountdown'

import { teaserPropsByView } from '@utils/placements'

const PromoStrip: React.FC<{
    placement: IPlacement | ICMCollection
    teaserIndex?: number
}> = ({ placement, teaserIndex }) => {
    const item = ('items' in placement ? placement.items[0] : placement.teasableItems[0]) as ILXTeaser
    const viewtype = placement?.viewtype
    const {
        promoteToH1,
        teaserText1,
        teaserText2,
        teaserLXCallToActionSettings,
        teaserIcon,
        placementCounter,
        media,
    } = item

    const theme = useTheme()
    const { teaserTitle, teaserOverlaySettings, teaserOverlayTextAlign } =
        teaserPropsByView(viewtype ?? 'default')

    const teaserTitleValue = item[teaserTitle] ?? ''
    const teaserTextValue = teaserText1 ?? teaserText2
    const teaserOverlaySettingsValue = item[teaserOverlaySettings] ?? ''
    const teaserOverlayTextAlignValue = item[teaserOverlayTextAlign] ?? ''
    const { isCountDownVisible } = useCountdown(item ?? undefined)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const CmMedia = useMemo(() => <CmsMedia
        media={media}
        cropType="SQUAT_BANNER_XS"
        lazy={true}
    />, [item]);

    return (
        <>
            <article
                className={clsx(styles['promo-strip-wrapper'], styles[teaserOverlaySettingsValue],
                    (isCountDownVisible ? styles['direction-column'] : '')
                )}
            >
                {CmMedia}

                <CmsCountdown styleOverride={styles} teaser={item} />

                {teaserIcon && (
                    <div className={styles['promo-strip-icon']}>
                        <CmsIcon size={isMobile ? 32 : 56} teaserIcon={teaserIcon} />
                    </div>
                )}

                <div className={styles['promo-strip-contents']}>
                    {teaserTitleValue && (
                        <CmsTeaserTitle
                            className={clsx(styles.title, styles[teaserOverlayTextAlignValue ?? 'left'])}
                            dataCmMetadata={`[{"_":"properties.${teaserTitle}"}]`}
                            variant={promoteToH1 ? 'h1' : 'h2'}
                        >
                            {teaserTitleValue}
                        </CmsTeaserTitle>
                    )}

                    <div className={`${styles[teaserOverlayTextAlignValue ?? 'left']}`} dangerouslySetInnerHTML={{ __html: teaserTextValue }} >
                    </div>

                    {teaserLXCallToActionSettings.length > 0 && (
                        <div className={`${styles['promo-strip-cta']} ${styles[teaserOverlayTextAlignValue ?? 'left']}`}>
                            {teaserLXCallToActionSettings.map((actionSettings, index) => {
                                return (
                                    <CmsCta
                                        dataElementId={`${getDataElementId(
                                            viewtype,
                                            {
                                                placementIndex: placementCounter?.placementIndex,
                                                tileIndex: teaserIndex ?? placementCounter?.tileIndex,
                                                actionIndex: teaserLXCallToActionSettings.length > 1 ? index : '',
                                            },
                                            'CTA'
                                        )}`}
                                        dataDescription={`${trimText(actionSettings?.target?.name ?? '', '_')}${trimText(
                                            actionSettings?.callToActionText ?? ''
                                        )}`}
                                        key={`cms-content__text-module--cta-${index}`}
                                        teaserCtaSetting={actionSettings}
                                    />
                                )
                            })}
                        </div>
                    )}
                </div>
            </article>

        </>

    )
}


export default PromoStrip