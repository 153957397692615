import { useMentionMe } from '@hooks/useMentionMe'
import styles from './styles/index.module.scss'
import MentionMeWrapper from '../MentionMeWrapper'

export const MentionMePlaceholder = () => {
  const { isEnabled } = useMentionMe('referreroffer', {
    situation: 'landingpage',
  })

  return isEnabled ? <MentionMeWrapper id="mmWrapperlandingpage" className={styles.wrapper} /> : null
}
